import { detailListService } from "@/api/businessModel";
import { BusinessFieldType } from "../../../../data/BusinessCommonHeader";

class RelationDataHandle {
  /**
   * 请求参数
   * @param column 控件数据
   * @param businessData 业务建模详情数据
   * @param pageModel 分页数据
   * @param parentData 父节点数据
   * @param level 层级
   * @returns {{area, controlField: *, pageNo: number, commonSet: {relationSet}, controlTempId: *, pageSize: number, ids: string, controlVersion: *, templateId}}
   */
  requestParams(column, businessData = {}, pageModel, parentData, level = 1) {
    const filterFields = pageModel?.filterFields;
    const sortField = pageModel?.sortField;
    if (level > 1) { // 子集
      pageModel = null;
    }
    let params = { };
    if (column.relationType === 2) {
      params = {
        templateId: column.templateDataId,
        pageNo: pageModel?.pageNo,
        pageSize: pageModel?.pageSize,
        filterFields,
        sortField,
        commParams: JSON.stringify({
          relationSet: {},
          controlTempId: column.templateId,
          controlField: column.field,
          controlVersion: column.version || businessData.mdInfo?.version
        }),
        relationType: column.relationType,
        controlType: 1,
        schemeVersionId: ""
      };
    } else if (column.relationType === 4) {
      params = {
        controlTempId: column.templateId,
        controlField: column.field,
        controlVersion: column.version,
        filterFields,
        sortField,
        pageNo: pageModel?.pageNo,
        pageSize: pageModel?.pageSize,
        templateId: column.templateDataId,
        area: level

      };
      if (level === 1) {
        params.parentId = "0";
        params.commonSet = JSON.stringify({ relationSet: this.relationSet(column) });
        params.ids = "";
      }
    }
    if (parentData) params.parentIds = parentData.id;
    params.field = column.selAreaField;
    if (Number(column.selAreaType) === 3) {
      if (level > 2) {
        params.parentField = column.selAreaParentField;
        params.field = column.selAreaField;
      } else {
        params.field = column.selAreaParentField;
      }
    }
    console.log(params);
    return params;
  }

  /**
   * 查询数据和列头
   * @param column 控件数据
   * @param businessData 业务建模详情数据
   * @param pageModel 分页数据
   * @param parentData 父节点数据
   * @param level 层级
   * @returns {Promise<unknown[]>}
   */
  queryDataAndInfos(column, businessData, pageModel, parentData, level) {
    const params = this.requestParams(column, businessData, pageModel, parentData, level);
    let queryFn;// 查询数据的方法
    if (column.relationType === 2) { // 基础数据
      queryFn = detailListService.mdQueryNewRelationDataList.bind(detailListService);
    } else if (column.relationType === 4) { // 业务建模数据
      queryFn = detailListService.mdQuerySubDataList.bind(detailListService);
    }

    const promise1 = new Promise((resolve, reject) => {
      queryFn(params).then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err);
      });
    });

    if (level > 1) { // 如果是子集
      if (Number(column.selAreaType) === 3 && level === 2) {
        const params1 = this.requestParams(column, businessData, pageModel, parentData, level + 1);
        return Promise.all([promise1, this.queryShowInfo(params), this.queryShowInfo(params1)]);
      }
      return Promise.all([promise1, this.queryShowInfo(params)]);
    }
    return Promise.all([promise1]);
  }

  /**
   * 获取显示列头
   * @param params
   * @returns {Promise<unknown>}
   */
  queryShowInfo(params) {
    return new Promise((resolve) => {
      detailListService.mdQuerySubDataColList(params).then((res) => {
        resolve(res);
      }).catch(() => {
        resolve({});
      });
    });
  }

  /**
   * 查询数据
   * @param column 控件数据
   * @param businessData 业务建模详情数据
   * @param pageModel 分页数据
   * @param parentData 父节点数据
   * @param level 层级
   * @returns {Promise<unknown>|Promise<T>}
   */
  queryData(column, businessData, pageModel, parentData, level) {
    pageModel = pageModel || {};
    if (!pageModel.data) pageModel.data = {};
    const pageNo = pageModel.pageNo;
    // if (pageModel.data[pageNo]) {
    //   return new Promise((resolve) => {
    //     resolve(pageModel.data[pageNo]);
    //   });
    // }
    return this.queryDataAndInfos(column, businessData, pageModel, parentData, level).then((response) => {
      const assistList = response[0].records || [];
      assistList.forEach((item) => {
        item.selectedAll = false;
        item.selected = false;
        item.children = null;
        item.openSubset = false;
        item.getParentNode = (() => parentData);
      });
      pageModel.total = response[0].total;
      pageModel.totalPages = response[0].totalPages;
      pageModel.data[pageNo] = assistList;
      pageModel.datas = assistList;
      pageModel.fieldStatistics = response[0].commParams.fieldStatistics || {};
      if (parentData) {
        this.change(parentData, assistList);
        parentData.children = assistList;
        parentData.showInfo = response[1];
        if (response.length === 3) {
          parentData.subShowInfo = response[2];
        }
      }
      return assistList;
    });
  }

  /**
   * 选中状态改变
   * @param data
   * @param children
   */
  change(data, children) {
    data.selectedAll = false;
    children = children || data.children;
    // 同步子节点数据选择状态
    if (children) {
      children.forEach((item) => {
        item.selected = data.selected;
        if (item.children) {
          item.children.forEach((item1) => {
            item1.selected = data.selected;
          });
        }
      });
    }
    // 同步父节点选中状态
    let parent = data.getParentNode();
    while (parent) {
      const selectedDatas = parent.children.filter((item) => item.selected);
      if (selectedDatas.length > 0) {
        parent.selected = true;
        parent.selectedAll = selectedDatas.length !== parent.children.length;
      } else {
        parent.selectedAll = false;
        parent.selected = false;
      }
      parent = parent.getParentNode();
    }
  }

  select(data, sid) {
    if (!sid.length) return;
    const keys = Object.keys(data).sort((item1, item2) => Number(item1) - Number(item2));
    const datas = [];
    keys.forEach((item) => {
      datas.push(...data[item]);
    });
    datas.forEach((item) => {
      if (sid.includes(item.id)) {
        item.selected = true;
        this.change(item);
      }
    });
  }

  /**
   * 显示值转换
   * @param data 数据
   * @param item 列头数据
   * @returns {string|*}
   */
  showName(data, item) {
    const value = data[item.field];
    if (!value) {
      return "";
    }
    const fieldType = Number(item.fieldType);
    if (fieldType === BusinessFieldType.inputAmount
      || fieldType === BusinessFieldType.inputNumber
      || fieldType === BusinessFieldType.inputCommon
      || fieldType === BusinessFieldType.multiText) {
      return String(value);
    }
    if (fieldType === BusinessFieldType.select
      || fieldType === BusinessFieldType.multiSelect
      || fieldType === BusinessFieldType.superior) {
      if (Array.isArray(value)) {
        return value.map((item) => item.name).join(",");
      }
    }
    if (fieldType === BusinessFieldType.address) {
      const val = [];
      if (value.provinceName) {
        val.push(value.provinceName);
      }
      if (value.cityName) {
        val.push(value.cityName);
      }
      if (value.districtName) {
        val.push(value.districtName);
      }
      if (value.address) {
        val.push(value.address);
      }
      return val.join(" ");
    }
    if (fieldType === BusinessFieldType.date) {
      return value.split(",")[0];
    }
    return "";
  }

  /**
   * 关联条件数据
   * @param column 控件数据
   * @returns {{}}
   */
  relationSet(column) {
    // dataFiltration 老接口未处理当前数据  后台取值筛选？
    const { relationCondition } = column;
    const relationSet = {};
    // const belongSectionType = this.column.belongSectionType;
    // 数据范围  关联条件查询  往上查  不往下  如有往下查询 逻辑需调整
    const areaFieldsObj = [column.nextLayerSameAreaFields, column.detailSameAreaFields, column.mainAreaFields];
    (relationCondition || []).forEach((item) => {
      // targetField 目标字段查询当前填写内容
      let targetColumn = null;
      let i = 0;
      // 从子集向上往主表查询 field字段
      while (!targetColumn && i >= 0 && i < areaFieldsObj.length) {
        areaFieldsObj[i] && (targetColumn = column?.findColumn(areaFieldsObj[i], item.targetField, "field"));
        i++;
      }
      targetColumn && Object.assign(relationSet, targetColumn.paramsForSubmit);
    });
    return relationSet;
  }

  /**
   * 获取选中数据
   * @param data
   * @param column
   * @returns {Promise<unknown>}
   */
  getSelectedDatas(data, column) {
    return new Promise((resolve) => {
      const datas = this.handleSelectedData(data, column);
      resolve(datas);
    });
  }

  /**
   * 选择数据数量
   * @param data 数据
   * @param column
   * @returns {number}
   */
  selectedDataCount(data, column) {
    const datas = this.handleSelectedData(data, column);
    return datas.length;
  }

  clear(data) {
    const keys = Object.keys(data).sort((item1, item2) => Number(item1) - Number(item2));
    const datas = [];
    keys.forEach((item) => {
      datas.push(...data[item]);
    });
    datas.forEach((item) => {
      item.selected = false;
      this.change(item);
    });
  }

  /**
   * 处理选择的数据
   * @param data
   * @param column
   * @returns {[]|*[]}
   */
  handleSelectedData(data, column) {
    const keys = Object.keys(data).sort((item1, item2) => Number(item1) - Number(item2));
    let datas = [];
    keys.forEach((item) => {
      datas.push(...data[item]);
    });
    datas = datas.filter((item) => item.selected);
    const selAreaType = Number(column.selAreaType);
    if (selAreaType === 1) {
      return datas;
    }
    if (datas.length === 0) {
      return [];
    }
    // 关联主表区
    if (Number(column.showListStyle) === 1) {
      datas.forEach((item) => {
        if (selAreaType === 2) {
          const mainId = item.mainId;
          const dataId = item.id;
          item.id = mainId;
          item.detailId = dataId;
        } else if (selAreaType === 3) {
          const mainId = item.mainId;
          const detailId = item.detailId;
          const dataId = item.id;
          item.id = mainId;
          item.detailId = detailId;
          item.itemDataId = dataId;
        }
      });
      return datas;
    }
    // 关联明细区
    if (selAreaType === 2) {
      const datas1 = [];
      datas.forEach((item1) => {
        let children = item1.children;
        if (!children) {
          children = item1[column.selAreaField] || [];
        } else {
          children = children.filter((item) => item.selected);
        }
        children.forEach((item2) => {
          const dict = { ...item1, ...item2 };
          dict.id = item1.id;
          dict.detailId = item2.id;
          datas1.push(dict);
        });
      });
      return datas1;
    }
    // 关联子集区
    if (selAreaType === 3) {
      const datas1 = [];
      datas.forEach((item1) => {
        let children1 = item1.children;
        if (!children1) {
          children1 = item1[column.selAreaParentField] || [];
        } else {
          children1 = children1.filter((item) => item.selected);
        }
        children1.forEach((item2) => {
          let children2 = item2.children;
          if (!children2) {
            children2 = item2[column.selAreaField] || [];
          } else {
            children2 = children1.filter((item) => item.selected);
          }
          children2.forEach((item3) => {
            const dict = { ...item1, ...item2, ...item3 };
            dict.id = item1.id;
            dict.detailId = item2.id;
            dict.itemDataId = item3.id;
            datas1.push(dict);
          });
        });
      });
      return datas1;
    }
    return [];
  }
}

export const relationDataHandle = new RelationDataHandle();
